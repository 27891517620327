<template>
  <!-- <v-form ref="form_title" v-model="valid"> -->
  <div>

    <v-row class="mt-0">
      <v-col cols="3">
        <p class="primary--text mb-0">Alias</p>
        <p class="grey--text"><small>Pour distinguer les souscriptions multiples</small></p>
      </v-col>
      <v-col cols="8">
        <v-text-field
            dense
            v-model="values.alias"
            outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="3">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.DISABLE_LABEL')}}</p>
        <p class="grey--text"><small>{{$t('CHANNEL_SUBSCRIPTION.DISABLE_TEXT')}}</small></p>
      </v-col>
      <v-col cols="8">
        <v-switch class="mt-1"
                  v-model="values.publication_disabled"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0" v-if="values && values.ttl_config && values.ttl_config.ttl_in_days">
        <v-col cols="3">
            <p class="primary--text mb-0">Dépublication</p>
            <p class="grey--text"><small>Les annonces seront enlevées après {{ values.ttl_config.ttl_in_days }} jours</small></p>
        </v-col>
        <v-col cols="4">
            <v-text-field
                    dense
                    outlined
                    v-model="values.ttl_config.ttl_in_days"
                    :disabled="!values.ttl_config.enabled"
            ></v-text-field>
        </v-col>
        <v-col cols="4">
            <v-switch class="mt-1"
                      v-model="values.ttl_config.enabled"
                      label="Activer"
            />
        </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.PRICES_TITLE')}}</p>
        <p class="grey--text"><small>{{$t('CHANNEL_SUBSCRIPTION.PRICES_TEXT')}}</small></p>

        <div v-if="values && values.channel && typeof values.channel.methods === 'object'">
          <div class="ma-0 d-flex" v-if="values.channel.methods.indexOf('organic') !== -1">
            <v-col class="pa-0" cols="3">
              <v-switch class="mt-1"
              v-model="methods"
              value="organic"
              label="Organic"
              />
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-chip outlined
              class="mt-1"
              color="grey"
              label>
              {{$t('COMMON.FREE')}}
            </v-chip>
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-input type="hidden"
              v-model="prices.organic"
              :value="0"
              />
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="values.channel.methods.indexOf('credit') !== -1">
            <v-col class="pa-0" cols="3">
              <v-switch class="mt-1"
              v-model="methods"
              value="credit"
              label="Crédits"
              />
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-chip outlined class="mt-1" color="grey" label>{{$t('COMMON.FIXED')}}</v-chip>
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-input type="hidden"
              v-model="prices.credit"
              :value="1"
              />
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="values.channel.methods.indexOf('cpa') !== -1">
            <v-col class="pa-0" cols="3">
              <v-switch class="mt-1"
              v-model="methods"
              value="cpa"
              label="CPA"
              />
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-text-field
                v-model="prices.cpa"
                @change="$emit('input', {prices:{cpa:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="$emit('input', {prices:{cpa:channel.prices.cpa || 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="values.channel.methods.indexOf('cpc') !== -1" >
            <v-col class="pa-0" cols="3">
              <v-switch class="mt-1"
              v-model="methods"
              value="cpc"
              label="CPC"
              />
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-text-field
                v-model="prices.cpc"
                @change="$emit('input', {prices:{cpc:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="$emit('input', {prices:{cpc:channel.prices.cpc || 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </div>
          <div class="ma-0 d-flex" v-if="values.channel.methods.indexOf('cpm') !== -1">
            <v-col class="pa-0" cols="3">
              <v-switch class="mt-1"
              v-model="methods"
              value="cpm"
              label="CPM"
              />
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-text-field
                v-model="prices.cpm"
                @change="$emit('input', {prices:{cpm:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="$emit('input', {prices:{cpm:channel.prices.cpm || 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </div>
        </div>

      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12">
        <p class="primary--text">{{$t('CHANNEL_SUBSCRIPTION.ACCOUNT_TITLE')}}</p>

        <permission name="use_xtramile_account">
          <div class="ma-0 d-flex">
            <v-col class="pa-0" cols="12">
              <v-switch class="mt-1"
              v-model="use_xtramile_account"
              @change="$emit('input', {use_xtramile_account:$event})"
              :value="true"
              :false-value="false"
              :true-value="true"
              :label="$t('CHANNEL_SUBSCRIPTION.USE_XTRAMILE_ACCOUNT', {a: channel.title})"
              ></v-switch>
            </v-col>
          </div>
        </permission>

        <div :class="[use_xtramile_account ? 'muted' : '']">

          <template v-for="(params, i) in channel.account_params">

            <template v-if="params.type === 'boolean'">
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-select
                  v-model="account_params[params.name]"
                  @change="$emit('input', {account_params:{[params.name]:$event}})"
                  :placeholder="params.name"
                  :disabled="use_xtramile_account"
                  :items="$t('CHANNEL_SUBSCRIPTION.BOOLEAN_ITEMS')"
                  outlined
                  dense
                  hide-details
                  :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                  required
                  ></v-select>
                </v-col>
              </div>
            </template>

            <template v-else-if="params.type === 'number'">
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-text-field
                  v-model="account_params[params.name]"
                  @change="$emit('input', {account_params:{[params.name]:$event}})"
                  type="number"
                  min="0"
                  :placeholder="params.name"
                  :disabled="use_xtramile_account"
                  outlined
                  dense
                  hide-details
                  :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                  required
                  ></v-text-field>
                </v-col>
              </div>
            </template>

            <template v-else>
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-text-field
                  v-model="account_params[params.name]"
                  @change="$emit('input', {account_params:{[params.name]:$event}})"
                  :placeholder="params.name"
                  :disabled="use_xtramile_account"
                  outlined
                  dense
                  hide-details
                  :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                  required
                  ></v-text-field>
                </v-col>
              </div>
            </template>

          </template>

        </div>
      </v-col>
    </v-row>


    <v-row class="mt-0">
      <v-col cols="12">
        <p class="primary--text">{{ $t('CHANNEL_SUBSCRIPTION.CHANNEL_PARAMS_TITLE') }}</p>
        <div class="ma-0 d-flex">
          <v-col class="pa-0 pt-2" cols="3">
            <small class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.APPLY_METHOD')}}</small>
          </v-col>
          <v-col class="pa-0" cols="9">
            <v-select
                v-model="channel_params.apply_method"
                :items="$t('CHANNEL_SUBSCRIPTION.APPLY_METHOD_ITEMS')"
                class="text-nowrap"
                item-text="text"
                item-value="value"
                @change="$emit('input', {channel_params:{apply_method:$event}})"
                outlined
                dense
            />
          </v-col>
        </div>
        <div class="ma-0 d-flex">
          <v-col class="pa-0" cols="12">
            <v-switch
              class="mt-1"
              v-model="channel_params.test_mode"
              @change="$emit('input', {channel_params:{test_mode:$event}})"
              :false-value="false"
              :true-value="true"
              :value="true"
              color="warning"
              :label="$t('CHANNEL_SUBSCRIPTION.TEST_MODE', {a: channel.title})"
            />
          </v-col>
        </div>
        <div>

          <template v-for="(params, i) in channel.channel_params">

            <template v-if="params.type === 'boolean'">
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-select
                  v-model="channel_params[params.name]"
                  @change="$emit('input', {channel_params:{[params.name]:$event}})"
                  :placeholder="params.name"
                  :items="$t('CHANNEL_SUBSCRIPTION.BOOLEAN_ITEMS')"
                  outlined
                  dense
                  hide-details
                  :rules="params.required ? [$rules.required] : [true]"
                  required
                  />
                </v-col>
              </div>
            </template>

            <template v-else-if="params.type === 'number'">
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-text-field
                  v-model="channel_params[params.name]"
                  @change="$emit('input', {channel_params:{[params.name]:$event}})"
                  type="number"
                  min="0"
                  :placeholder="params.name"
                  outlined
                  dense
                  hide-details
                  :rules="params.required ? [$rules.required] : [true]"
                  required
                  />
                </v-col>
              </div>
            </template>

            <template v-else>
              <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                <v-col class="pa-0 pt-2" cols="3">
                  <small class="grey--text">{{params.name}}</small>
                </v-col>
                <v-col class="pa-0" cols="8">
                  <v-text-field
                  v-model="channel_params[params.name]"
                  @change="$emit('input', {channel_params:{[params.name]:$event}})"
                  :placeholder="params.name"
                  outlined
                  dense
                  hide-details
                  :rules="params.required ? [$rules.required] : [true]"
                  required
                  />
                </v-col>
              </div>
            </template>
          </template>
        </div>
      </v-col>
    </v-row>


  </div>
  <!-- </v-form> -->
</template>

<script>
import Permission from "@/components/Permission"

export default {
  name:"FormChannelSubscriptionCompactIntro",
  components: {
    Permission,
  },
  data: () => ({
    valid: false,
    //
    channel: {},
    channel_params: {test_mode:true, apply_method: 'APPLY_URL'},
    account_params: {},
    hash_account: null,
    prices: {},
    methods: [],
    use_xtramile_account: false
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    methods (n,o) {
      if (n && n.length !== o.length) {
        n.filter(v => this.channel.methods.indexOf(v) !== -1)
        this.$emit('input', {methods: n})
      }
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })

      // redefine methods
      if (this.channel && this.channel.prices) {
        if (!this.prices) this.prices = {}
        Object.keys(this.channel.prices).forEach((key, i) => {
          if (!this.prices[key]) this.prices[key] = this.channel.prices[key]
        })
      }

    }
  }
}
</script>
